@-webkit-keyframes shadow-drop-center {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.35);
    box-shadow: 0 0 100px 0px rgba(0, 0, 0, 0.35);
  }
}
@keyframes shadow-drop-center {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.45);
    box-shadow: 0 0 100px 0px rgba(0, 0, 0, 0.45);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes slide-fwd-center {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
  100% {
    -webkit-transform: translateZ(160px);
    transform: translateZ(160px);
  }
}
@keyframes slide-fwd-center {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
  100% {
    -webkit-transform: translateZ(160px);
    transform: translateZ(160px);
  }
}

@-webkit-keyframes slide-top {
  0% {
    -webkit-transform: translateY(-100px);
    transform: translateY(-100px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes slide-top {
  0% {
    -webkit-transform: translateY(-100px);
    transform: translateY(-100px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

.App {
  font-family: "Poppins";
}

.headerLogo {
  position: absolute;
  top: 0;
  height: 175%;
  margin-left: -1rem;
  float: left;
  filter: drop-shadow(15px 15px 10px rgba(101, 119, 85, 0.863));
}

.headerLogosmall {
  position: absolute;
  top: 0;
  height: 7rem;
  padding-top: 2%;
  margin-left: -0.5rem;
  float: left;
  filter: drop-shadow(0 0 20px rgba(101, 119, 85, 0.726));
}

.menuLogo {
  position: absolute;
  height: 7rem;
}

.headerLogo:hover {
  cursor: pointer;
}

.header {
  padding-top: 1rem;
  padding-bottom: 1rem;
  position: sticky;
  width: 100%;
  left: 0%;
  right: 0%;
  top: 0px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  z-index: 100;
  background-image: linear-gradient(to top, rgba(255, 255, 255, 0), #1c5040c7),
    none;
  margin: 0;
  -webkit-animation: slide-top 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-top 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.tabs {
  list-style-type: none;
  margin: 0;
  padding: 10px;
  font-style: underline;
  overflow: hidden;
  text-align: center;
}

.tab {
  background-color: #699186;
  border-width: 1px;
  border-style: solid;
  float: right;
  top: 50%;
  color: #fff5bc;
  display: inline;
  padding: 0.75em 1em;
  border-radius: 5px;
  margin-right: 1rem;
}

.tab:hover {
  background-color: #53796f;
  cursor: pointer;
}

.menuImg {
  width: 25%;
}

.component {
  position: absolute;
  top: 0;
  height: 100%;
  background-size: auto;
  padding-top: 10rem;
  position: absolute;
  margin: 0;
  width: 100%;
  /* z-index: -3; */
}

.menuCont {
  position: absolute;
  top: 0;
  height: 100%;
  width: 70%;
  margin: 0 15% 0 15%;
  border-style: solid;
  border-width: 0 0 0 0.3px;
  border-color: rgba(191, 191, 191, 0.863);
  display: table;
  flex-direction: row;
  align-items: end;
}

.menu {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: stretch;
}

#Mapi:hover {
  background-image: url(./assets/bigMenu/apiMask.png);
  background-size: cover;
  animation: shadow-drop-center 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: fadeIn 0.4s linear;
}

#Mlive:hover {
  background-image: url(./assets/bigMenu/liveMask.png);
  background-size: cover;
  animation: shadow-drop-center 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: fadeIn 0.4s linear;
}

#Magri:hover {
  background-image: url(./assets/bigMenu/agriMask.png);
  background-size: cover;
  animation: shadow-drop-center 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: fadeIn 0.4s linear;
}

#Mhydro:hover {
  background-image: url(./assets/bigMenu/hydroMask.png);
  background-size: cover;
  animation: shadow-drop-center 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: fadeIn 0.4s linear;
}

#hoverId:hover {
  -webkit-animation: shadow-drop-center 0.4s
    cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: shadow-drop-center 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.menuItem {
  height: 100%;
  width: 25%;
  border-style: solid;
  border-width: 0 0.3px 0 0;
  border-color: rgba(191, 191, 191, 0.863);
  transition: background-image 0.7s;
}

.menuitem:hover {
  z-index: -1;
}

.pddnTop {
  position: relative;
  margin: auto 5% 0 5%;
  top: 40%;
  transform: translateY(-50%);
}

.slctds {
  filter: drop-shadow(0px 0px 10px rgb(255, 255, 255));
}

.fadeBG {
  text-shadow: 0px 0px 20px #474747;
}

.legible {
  filter: invert(19%);
  -webkit-filter: invert(19%);
  -moz-filter: invert(19%);
}

.fadeBG2 {
  text-shadow: 0px 0px 20px #474747;
}

.fadein {
  animation: fadeIn 0.5s linear;
  -webkit-animation: fadeIn 0.5s linear;
  -moz-animation: fadeIn 0.5s linear;
  -o-animation: fadeIn 0.5s linear;
  -ms-animation: fadeIn 0.5s linear;
}

#backgroundSmall {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: -100;
}

.spBodyTxt {
  color: white;
}

.hoMenuSmall {
  background-image: linear-gradient(to bottom, #ffffff00, #000000bf), none;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
}

.navbarCont {
  display: flex;
  justify-content: space-around;
  margin: 0.7rem 0.5rem 0.7rem 0.5rem;
}

.navbarIcon {
  height: 3.5rem;
}

.bgImgHome {
  width: 100%;
  height: 100%;
  animation: fadeIn 0.5s linear;
  -webkit-animation: fadeIn 0.5s linear;
  -moz-animation: fadeIn 0.5s linear;
  -o-animation: fadeIn 0.5s linear;
  -ms-animation: fadeIn 0.5s linear;
}

.special {
  width: 100%;
  padding-top: 3em;
}

iframe {
  z-index: -98515092309 !important;
}

.footer {
  left: 0;
  right: 0;
  margin-top: 0;
  background-color: #334d45;
  display: flex;
  justify-content: space-evenly;
  padding-top: 3em;
  padding-bottom: 1em;
  bottom: 0;
  z-index: 10000;
}

.footersmall {
  bottom: 0;
  left: 0;
  right: 0;
  background-image: linear-gradient(to bottom, #ffffff00, #000000bf), none;
  display: flex;
  justify-content: space-evenly;
}

.absolute {
  position: absolute;
  bottom: 0rem;
  top: 95%;
  background: none !important;
}

.relative {
  position: relative;
}

.spBody {
  margin-top: 0;
  background-color: #334d45;
  padding-top: 1em;
}

.subP {
  font-size: 19px;
}

.subPP {
  margin-bottom: 2rem;
}

.foIcon {
  height: 4em;
}

.iCont {
  width: 15em;
  color: #ffec82;
  z-index: 100;
}

.iContsmall {
  width: 25%;
  color: #fff4b6;
  font-size: 0.75rem;
}

.bgImg {
  top: 0rem;
  right: 0;
  left: 0;
  bottom: 0;
  position: fixed;
  z-index: -100;
  min-height: 940px;
  width: 100%;
  height: 100%;
  animation: fadeIn 0.5s linear;
  -webkit-animation: fadeIn 0.5s linear;
  -moz-animation: fadeIn 0.5s linear;
  -o-animation: fadeIn 0.5s linear;
  -ms-animation: fadeIn 0.5s linear;
}

.homeBG {
  height: 100%;
  width: 100%;
}

.smallinput {
  margin: 0 15% 0 15%;
  width: 70%;
}

.formsmall {
  padding: 5%;
  border-radius: 7px;
  margin: 10%;
  padding-bottom: 3em;
  background-image: linear-gradient(to left, rgba(255, 255, 255, 0), #334d45),
    none;
}

.shadowImg {
  height: 100%;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), #334d45),
    none;
}

.shadowImgg {
  height: 100%;
  background-image: linear-gradient(to bottom, #334d4596, #334d45), none;
}

.aboutWidth {
  margin: 0 10% 0 10%;
  animation: fadeIn 0.5s linear;
  -webkit-animation: fadeIn 0.5s linear;
  -moz-animation: fadeIn 0.5s linear;
  -o-animation: fadeIn 0.5s linear;
  -ms-animation: fadeIn 0.5s linear;
}

.Cont {
  width: 50%;
  right: 50%;
  transform: translateX(50%);
  padding-bottom: 10rem;
}

.width-70 {
  position: relative;
  width: 60%;
  left: 20%;
}

.spTitle {
  padding-top: 3rem;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  color: white;
  font-size: 3em;
}

.paragraph {
  color: white;
  font-family: "Poppins";
  letter-spacing: 0.1rem;
  line-height: 22px;
}

.paragraphh {
  letter-spacing: 0.16rem;
  font-size: 18px;
}

.mouse {
  padding-top: 1rem;
  position: absolute;
  height: 3.8em;
  left: 50%;
  transform: translateX(-50%);
}

.form {
  margin: 0 auto;
  color: #fff5bc;
  background: linear-gradient(
    90deg,
    rgba(83, 121, 111, 0.05) 0%,
    rgba(83, 121, 111, 0.8) 100%
  );
  width: 70%;
  height: 23rem;
  padding: 2.5rem;
  border-radius: 5px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  margin-bottom: 5rem;
  margin-top: 3rem;
}

.flex-container {
  display: flex;
}

.subtitle {
  font-style: normal;
  font-weight: 600;
  color: #fff1a1;
  font-size: 2.75em;
}

.bigger {
  font-size: 2.5rem;
}

.conTitle {
  color: #fff5bc;
}

.submitBtn {
  float: right;
  border-radius: 2px;
  border-width: 0px;
  border-color: #151f1c;
  border-style: solid;
  background-color: #ffffff;
  padding: 0.45rem;
}

.topTag {
  font-size: 15px;
}

.mailText {
  position: relative;
  margin-left: 0px;
  margin-right: 0px;
  width: 100%;
  height: 100%;
}

.pi {
  font-size: 1.75em !important;
}

.progCont {
  height: 1.25%;
  width: 100%;
  vertical-align: bottom;
  z-index: 999;
}

.progContsmall {
  height: 0.2rem;
  width: 100%;
  vertical-align: bottom;
  z-index: 999;
}

.progBar {
  z-index: 999;
  height: 100%;
  background-color: #ffffff;
}

.Mtitle {
  position: relative;
  color: whitesmoke;
  font-size: 2.5vw;
  display: table-cell;
  vertical-align: middle;
}

.menu1 {
  position: relative;
  top: 50%;
  transform: translateY(50%);
}

.Mtitle2 {
  position: relative;
  bottom: -85%;
  color: whitesmoke;
  font-size: 2vw;
  filter: drop-shadow(10px 4px 4px rgba(28, 43, 25, 0.849));
}

.menuTCont {
  display: table;
  width: 100%;
  height: 98.75%;
  text-align: center;
  width: 100%;
}

.iguess {
  height: 100%;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), #334d45),
    none;
}

.spNav {
  color: white;
  position: fixed;
  right: 0;
  padding-right: 2rem;
  background: rgb(0, 36, 10);
  background: linear-gradient(
    90deg,
    rgba(83, 121, 111, 0) 0%,
    rgba(83, 121, 111, 0.8) 100%
  );
  padding-left: 1rem;
}

.spNavTitlesmall {
  color: white;
  position: fixed;
  right: 0;
  width: 7em;
  padding-right: 0.3rem;
  background: rgb(0, 36, 10);
  background: linear-gradient(
    90deg,
    rgba(83, 121, 111, 0) 0%,
    rgba(83, 121, 111, 0.8) 100%
  );
  padding-left: 2rem;
  bottom: 0;
}

.spTab:hover {
  cursor: pointer;
}

.spTab {
  text-decoration: underline;
}

.box {
  border-radius: 3.5px;
  border: none;
  margin-top: 0.5rem;
}

.box1 {
  border-radius: 2.5px;
  border: none;
  margin-top: 0.3rem;
}

.textCarouselCont {
  color: white;
  position: absolute;
  width: 100%;
  height: 100%;
  display: table-cell;
  vertical-align: middle;
  padding: 13% 0 20% 0;
  animation: fadeIn 0.5s linear;
  -webkit-animation: fadeIn 0.5s linear;
  -moz-animation: fadeIn 0.5s linear;
  -o-animation: fadeIn 0.5s linear;
  -ms-animation: fadeIn 0.5s linear;
}

.textCarouselBG {
  padding-top: 0.1rem;
  padding-bottom: 1.1rem;
  text-shadow: -1px 1px 21px rgba(0, 0, 0, 0.767);
}

.carouselTitle {
  position: relative;
  z-index: 500;
  font-size: 3.5vw;
  padding: 0.001rem 0 0.001rem 0;
}

.carouselTitlesmall {
  font-size: 1.85rem;
}

.carouselP {
  position: relative;
  z-index: 500;
  padding: 0 20% 0 20%;
  font-size: 1.4vw;
  letter-spacing: 0.11rem;
}

.carouselPsmall {
  position: relative;
  z-index: 500;
  font-size: 1.1rem;
  letter-spacing: 0.05rem;
  line-height: 22px;
}

.spTabImg {
  height: 6rem;
  border-style: solid;
  border-color: rgb(182, 218, 166);
  border-width: 2px;
}

.spTabText {
  position: relative;
  top: -5rem;
  left: -0.5rem;
  font-size: 17px;
}

.tabImgCont {
  height: 6rem;
  margin: 0.2rem 0 0.4rem 0;
}

.spTabImgh {
  height: 6rem;
  width: 83%;
  border-style: solid;
  border-color: rgb(182, 218, 166);
  border-width: 2px;
}

.selctd {
  padding-right: 0.5rem;
}

.black {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgb(255, 253, 246);
  z-index: -1000;
}

.btnLearn {
  position: relative;
  z-index: 500;
  background-color: #699186;
  border-width: 1px;
  border-style: solid;
  color: white;
  border-color: #ffec82;
  padding: 1.2% 1.7%;
  border-radius: 5px;
  top: 50%;
  display: inline;
  margin-right: 1rem;
  -webkit-animation: slide-fwd-center 0.45s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: slide-fwd-center 0.45s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.flash {
  position: absolute;
  z-index: 1001;
  height: 100%;
  width: 100%;
  top: 0;
  -webkit-box-shadow: inset 0px 0px 400px 1000px rgba(255, 255, 255, 1);
  -moz-box-shadow: inset 0px 0px 400px 1000px rgba(255, 255, 255, 1);
  box-shadow: inset 0px 0px 3000px 400px rgba(255, 255, 255, 1);
  animation: fade 1s linear;
  -webkit-animation: fade 1s linear;
  -moz-animation: fade 1s linear;
  -o-animation: fade 1s linear;
  -ms-animation: fade 1s linear;
}

@keyframes fade {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

/* ----- RESPONSIVE ----- */

.bgsmall {
  background-image: url(./assets/bigMenu/liveBG.jpg);
  background-size: cover;
}

.bgImgRes {
  top: 0rem;
  position: fixed;
  width: 100%;
  z-index: -100;
}

.menu1 {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.Mtitle1 {
  position: relative;
  color: whitesmoke;
  font-size: 2rem;
  border-style: solid;
  border-width: 2px 0 2px 0;
  border-color: rgb(0, 0, 0);
  margin: 0.7rem;
  padding: 0.5rem;
}

.headerLogo1 {
  position: fixed;
  height: 100%;
}

.ham {
  position: fixed;
  height: 2.5rem;
  right: 0;
  padding-right: 6%;
  padding-top: 4%;
  transform: translate3d(0, 0, 0);
  perspective: 1000;
}

.hammenu {
  margin: 1em 60% 1em 0;
  padding: 0.5rem;
  border-radius: 5px;
  list-style-type: none;
  margin-left: -1rem;
  color: white;
  background-color: #ffffff5b;
  border-width: 1.5px;
  border-style: solid;
  border-color: #fff5bc;
}

.hammenus {
  margin: 0em 65% 0.5em 0;
  padding: 0.3rem;
  border-radius: 5px;
  list-style-type: none;
  margin-left: -1rem;
  color: white;
  font-weight: lighter;
  background-color: #ffffff21;
  border-width: 1px;
  border-style: solid;
  font-size: 14px;
}

.nrs {
  width: 100%;
}

.smallmenutab {
  border-width: 1px;
  border-style: solid;
  border-color: #fff5bc;
  padding: 0.5rem;
  margin-bottom: 1rem;
  color: white;
  -webkit-box-shadow: 10px 10px 0px 0px rgba(15, 64, 46, 0.48);
  -moz-box-shadow: 10px 10px 0px 0px rgba(15, 64, 46, 0.48);
  box-shadow: 10px 10px 0px 0px rgba(15, 64, 46, 0.48);
}

#apiw {
  background-image: url(./assets/bigMenu/apiMask.png);
  -webkit-animation: shadow-drop-center 0.4s
    cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: shadow-drop-center 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  background-size: 5rem;
}

#livew {
  background-image: url(./assets/bigMenu/liveMask.png);
  -webkit-animation: shadow-drop-center 0.4s
    cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: shadow-drop-center 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  background-size: 5rem;
}

#agriw {
  background-image: url(./assets/bigMenu/agriMask.png);
  -webkit-animation: shadow-drop-center 0.4s
    cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: shadow-drop-center 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  background-size: 5rem;
}

#hydrow {
  background-image: url(./assets/bigMenu/hydroMask.png);
  -webkit-animation: shadow-drop-center 0.4s
    cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: shadow-drop-center 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  background-size: 5rem;
}
