.react-responsive-modal-root {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}

.react-responsive-modal-overlay {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
}

.react-responsive-modal-container {
  height: 100%;
  outline: 0;
  overflow-x: hidden;
  overflow-y: auto;
  text-align: center;
}

/* Used to trick the browser to center the modal content properly  */
.react-responsive-modal-containerCenter:after {
  width: 0;
  height: 100%;
  content: "";
  display: inline-block;
  vertical-align: middle;
}

.react-responsive-modal-modal {
  font-family: "Poppins";
  text-decoration: none;
  max-width: 800px;
  display: inline-block;
  text-align: left;
  vertical-align: middle;
  background: #00000080;
  box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.25);
  margin: 1.2rem;
  padding: 1.2rem;
  padding-top: 3rem;
  position: relative;
  overflow-y: auto;
  border-radius: 5px;
  top: -2.5rem;
  left: -2rem;
  right: 10%;
  bottom: -2rem;
  position: absolute;
  -webkit-animation: slide-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.react-responsive-modal-closeButton {
  position: absolute;
  top: 40px;
  right: 20px;
  border: none;
  padding: 0;
  cursor: pointer;
  background-color: transparent;
  display: flex;
  filter: invert(100%);
  -webkit-filter: invert(100%);
  -moz-filter: invert(100%);
}

@keyframes react-responsive-modal-overlay-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes react-responsive-modal-overlay-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes react-responsive-modal-modal-in {
  0% {
    -webkit-transform: translateX(-420px);
    transform: translateX(-420px);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes react-responsive-modal-modal-out {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(-420px);
    transform: translateX(-420px);
  }
}
